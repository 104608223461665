var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"usagers","fluid":"","tag":"section","data-cy":"pageGestionUsagers"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-account-settings-outline","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t('menu.usagers'))+" ")])]},proxy:true}])},[_c('v-text-field',{staticClass:"ml-auto",staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":_vm.$t('general.chercher'),"hide-details":"","single-line":"","data-cy":"tfRechercheUsagers"},model:{value:(_vm.mots),callback:function ($$v) {_vm.mots=$$v},expression:"mots"}}),_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.listeUsagers,"options":_vm.options,"server-items-length":_vm.nUsagers,"loading":_vm.loading,"footer-props":{
        itemsPerPageOptions: [10,50,100,500],
        itemsPerPageText: _vm.$t('general.lignes_par_page'),
        pageText: ("{0}-{1} " + (_vm.$t('general.de')) + " {2}")
      },"data-cy":"dtUsager"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.dateCreation",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formaterDate(item.dateCreation))+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"px-2 ml-1 primary",attrs:{"min-width":"0","small":""},on:{"click":function($event){return _vm.onEditer(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{staticClass:"px-2 ml-1 warning",attrs:{"min-width":"0","small":""},on:{"click":function($event){return _vm.onSupprimer(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-trash-can-outline ")])],1)]}}],null,true)}),_c('v-btn',{staticClass:"primary",attrs:{"data-cy":"btnAjouterUsager"},on:{"click":function($event){return _vm.onAjouter()}}},[_vm._v(" "+_vm._s(_vm.$t('gestionUsager.creer_un_nouvel_usager'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }