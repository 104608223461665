<template>
  <v-container
    id="usagers"
    fluid
    tag="section"
    data-cy="pageGestionUsagers"
  >
    <base-material-card
      icon="mdi-account-settings-outline"
      inline
      class="px-5 py-3"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          {{ $t('menu.usagers') }}
        </div>
      </template>

      <v-text-field
        v-model="mots"
        append-icon="mdi-magnify"
        class="ml-auto"
        :label="$t('general.chercher')"
        hide-details
        single-line
        style="max-width: 250px;"
        data-cy="tfRechercheUsagers"
      />

      <v-divider class="mt-3" />

      <v-data-table
        :headers="headers"
        :items="listeUsagers"
        :options.sync="options"
        :server-items-length="nUsagers"
        :loading="loading"
        :footer-props="{
          itemsPerPageOptions: [10,50,100,500],
          itemsPerPageText: $t('general.lignes_par_page'),
          pageText: `{0}-{1} ${$t('general.de')} {2}`
        }"
        data-cy="dtUsager"
      >
        <template v-slot:[`item.dateCreation`]="{ item }">
          {{ formaterDate(item.dateCreation) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            class="px-2 ml-1 primary"
            min-width="0"
            small
            @click="onEditer(item)"
          >
            <v-icon
              small
            >
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn
            class="px-2 ml-1 warning"
            min-width="0"
            small
            @click="onSupprimer(item)"
          >
            <v-icon
              small
            >
              mdi-trash-can-outline
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-btn
        class="primary"
        data-cy="btnAjouterUsager"
        @click="onAjouter()"
      >
        {{ $t('gestionUsager.creer_un_nouvel_usager') }}
      </v-btn>
    </base-material-card>
  </v-container>
</template>

<script>
  import restApiService from '@/services/restApiService.js'
  import i18n from '@/i18n.js'

  export default {
    name: 'Usagers',

    data: () => ({
      loading: true,
      nouvelusager: {
        roles: 'user',
        creePar: 'Toucan-client',
        courriel: '',
        nomUsager: '',
        nom: '',
        prenom: '',
        adresse: {
        },
      },
      mots: '',
      options: {},
      nUsagers: 0,
      listeUsagers: [],
      headers: [
        { text: i18n.t('general.prenom'), value: 'prenom' },
        { text: i18n.t('general.nom_de_famille'), value: 'nom' },
        { text: i18n.t('general.courriel'), value: 'courriel' },
        { text: i18n.t('general.création'), value: 'dateCreation' },
        { text: i18n.t('gestionUsager.rôles'), value: 'roles' },
        { text: i18n.t('general.actions'), value: 'actions' },
      ],
    }),
    watch: {
      options: {
        handler () {
          this.getData()
        },
        deep: true,
      },
      mots: {
        handler () {
          this.getData()
        },
        deep: true,
      },

    },
    mounted () {
      this.getData()
    },
    methods: {
      getData () {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        const s = sortDesc[0] ? ',desc' : ''
        const arg = `size=${itemsPerPage}&page=${page - 1}&sort=${sortBy + s}&mots=${this.mots}`
        restApiService
          .get('/api/usagers?' + arg)
          .then((result) => {
            this.listeUsagers = result.data
            this.nUsagers = parseInt(result.headers['x-total-count'])
            this.loading = false
          })
          .catch((erreur) => alert(erreur))
      },
      onEditer (usager) {
        this.$router.push({ path: '/admin/editionUsager/' + usager.id })
      },
      async onSupprimer (usager) {
        const reponse = await this.$dialog.confirm({
          title: this.$t('usager.supprimer_un_usager'),
          text: this.$t('usager.voulez_vous_vraiment_supprimer_cet_usager_?'),
        })
        if (reponse) {
          restApiService.delete('/api/usagers/' + usager.id).then((result) => {
            this.getData()
          }).catch((erreur) => alert(erreur))
        }
      },
      async onAjouter () {
        const email = await this.$dialog.prompt({
          title: i18n.t('gestionUsager.creer_un_nouvel_usager'),
          text: this.$t('usager.veuillez_saisir_ladresse_courriel_de_lusager') + ' *',
          rules: [
            v => !!v || this.$t('general.le_champ_est_requis'),
            v => /.+@[^@]+\.[^@]{2,}$/.test(v) || this.$t('general.veuillez_saisir_une_adresse_email_valide'),
          ],
          textField: {
            type: 'email',
            id: 'nouvelUsagerEmailInput',
          },
          actions: {
            false: i18n.t('general.annuler'),
            true: {
              color: 'red',
              text: this.$t('general.confirmer'),
              handle: () => {
                return new Promise(resolve => {
                  this.nouvelusager.courriel = document.getElementById('nouvelUsagerEmailInput').value
                  if (this.nouvelusager.courriel && this.nouvelusager.courriel.match(/.+@[^@]+\.[^@]{2,}$/)) {
                    resolve(true)
                  } else { resolve(false) }
                })
              },
            },
          },
        })
          .catch(() => {
            console.log('Prompt dismissed')
          })

        if (email) {
          restApiService
            .get('/api/usagers/existe/' + this.nouvelusager.courriel)
            .then((result) => {
              if (!result.data.existe) {
                this.nouvelusager.nomUsager = this.nouvelusager.courriel
                restApiService
                  .post('/api/usagers', this.nouvelusager)
                  .then((result) => {
                    this.$router.push({ path: `/admin/editionUsager/${result.data.id}` + '?nouveau=true' })
                  })
                  .catch((erreur) => alert(erreur))
              } else {
                this.$dialog.error({
                  text: this.$t('usager.cet_usager_existe_déjà_!'),
                  title: i18n.t('general.erreur'),
                  actions: {
                    true: {
                      text: i18n.t('general.fermer'),
                    },
                  },
                })
              }
            })
            .catch((erreur) => alert(erreur))
        }
      },
    },
  }
</script>
